














































import { Component, Prop, Vue } from 'vue-property-decorator';
import moment from 'moment';
import Manager from '@/models/Manager';

@Component({
    components: {
        EditDriverComponent: () => import(
            /* webpackChunkName: "EditDriverComponent" */
            '@/components/EditDriver.component.vue')
    }
})
export default class ManagersTableComponent extends Vue {
    @Prop({ required: true })
    public managers!: Manager[];

    @Prop({ required: true })
    public onEditClicked!: (manager: Manager) => void;

    @Prop({ required: true })
    public onDeleteClicked!: (manager: Manager) => void;

    @Prop({ required: true })
    public onChangeStatusClicked!: (manager: Manager) => void;

    @Prop({ default: '' })
    public searchValue!: string;

    @Prop({ required: true, default: false })
    private isLoading!: boolean;

    public tableHeaders = [{
        text: this.$t('MANAGERS.TABLE.LOGIN'),
        value: 'login',
        sortable: true
    }, {
        text: this.$t('MANAGERS.TABLE.EMAIL'),
        value: 'email',
        sortable: false
    }, {
        text: this.$t('MANAGERS.TABLE.LAST_LOGIN'),
        value: 'lastLogin',
        sortable: false
    }, {
        text: this.$t('MANAGERS.TABLE.STATUS'),
        value: 'status',
        sortable: false
    }, {
        text: this.$t('MANAGERS.TABLE.IS_ACTIVATED'),
        value: 'hasSetPassword',
        sortable: false
    }, {
        text: this.$t('MANAGERS.TABLE.ACTIONS'),
        value: 'actions',
        align: 'end',
        sortable: false
    }];

    private editManager(item: Manager): void {
        this.onEditClicked(item);
    }

    private deleteManager(item: Manager) {
        this.onDeleteClicked(item);
    }

    public changeStatus(item: Manager) {
        this.onChangeStatusClicked(item);
    }

    private formatDate(value: string): string {
        if (!value) return '-';
        const date = moment(value, moment.ISO_8601, true);
        return date.format('DD.MM.yyyy HH:mm');
    }
}

