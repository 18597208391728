var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"driver-table"},[_c('v-card',{staticClass:"box-standard-shadow table-container py-1"},[_c('v-data-table',{staticClass:"row-pointer",attrs:{"items":_vm.managers,"headers":_vm.tableHeaders,"loading":_vm.isLoading,"search":_vm.searchValue,"items-per-page":10},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.status ? 'Active': 'Inactive')+" ")])]}},{key:"item.lastLogin",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(item.lastLogin))+" ")])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.email ? item.email : '-')+" ")])]}},{key:"item.hasSetPassword",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.hasSetPassword ? _vm.$t('MANAGERS.ACTIVATED'): _vm.$t('MANAGERS.NOT_ACTIVATED'))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.changeStatus(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas "+_vm._s(item.status ? 'fa-user-check ' : 'fa-user-xmark'))])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteManager(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-trash")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }